<template>
  <div class="wrapper">
    <div>
      <router-link to="/">
        <img
          class="ml-2 mt-2"
          style="width: 30px"
          src="/img/TopNav/cancel.png"
        />
      </router-link>
    </div>
    <section class="register-form-wrapper p-4" v-show="action_login">
      <h3 class="text-center"><span class="text-dark">Login</span></h3>
      <div class="text-center text-dark mb-3">
        <h6 class="text-dark text-center">
          <strong>{{ $t("logIntoYourAccount") }}</strong>
        </h6>
        {{ $t("enterYourDetailsContinueGaming") }}
      </div>
      <div class="form-wrapper mb-3">
        <form id="login-form">
          <div class="mb-0">
            <input
              style="
                border: 1px black solid;
                border-radius: 5px;
                background-color: white;
                color: black;
                width: 100%;
                height: 50px;
                text-align: center;
              "
              type="tel"
              class="form-control"
              id="phoneInput"
              placeholder="Phone number"
              aria-label="Enter Phone number"
              name="mobile"
              v-model="msisdn"
            />
          </div>
          <small v-if="phoneInvalid" class="text-danger text-center"
            >Invalid phone number</small
          >
          <div class="mb-3"></div>
          <div class="mb-0">
            <div
              class="input-group"
              style="
                border: 1px black solid;
                border-radius: 5px;
                background-color: white;
                color: black;
                width: 100%;
                height: 50px;
                text-align: center;
              "
            >
              <input
                :type="type"
                class="form-control"
                id="inputPass"
                style="
                  border-radius: 5px;
                  background-color: white;
                  color: black;
                  text-align: center;
                "
                name="password"
                placeholder="Enter Password"
                aria-label="Enter Password"
                v-model="password"
              />
              <div
                class="input-group-prepend"
                style="
                  border-radius: 5px;
                  background-color: white;
                  color: black;
                  text-align: center;
                "
              >
                <span
                  style="background-color: var(--white)"
                  class="btn btn-sm mt-1"
                  type="button"
                  ><img
                    @click="showPassword"
                    style="width: 20px"
                    :src="btnText"
                /></span>
              </div>
            </div>
          </div>
          <small class="text-blue mb-3 d-none"
            >Enter the 4 digit code sent to your phone</small
          >
          <input type="hidden" name="utm_source" value="" />
          <input type="hidden" name="utm_medium" value="" />
          <input type="hidden" name="utm_campaign" value="" />
          <input type="hidden" name="referrer" value="" />
        </form>
      </div>

      <p class="text-dark mb-5">
        <strong style="float: right">
          <a @click="setGetResetCode">FORGOT PASSWORD?</a>
        </strong>
      </p>

      <div class="disclaimer text-center d-none text-dark">
        By continuing, you confirm that you are 18 years old or over and agree
        with the <a class="text-dark">Terms, conditions and policies</a> of
        Chopbet
      </div>

      <div class="button-wrapper text-center mb-3">
        <a
          v-if="!spinner"
          @click="handleLoginClick"
          class="join-button py-2 form-control"
          >Login</a
        >
        <a v-if="spinner" class="join-button py-2 form-control">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
            <br />
          </div>
        </a>
      </div>

      <div class="login-button-wrapper text-center p-3 mb-3">
        <div class="text-center text-dark mb-3">
          <div class="text-center text-dark mb-3">
            Don't have an account?
            <a @click="setSignup">
              <span style="text-decoration: underline">Join Now</span></a
            >
            <br />
          </div>
        </div>
      </div>
    </section>

    <section class="register-form-wrapper p-4" v-show="action_signup">
      <h3 class="text-dark text-center">
        Join <span class="text-dark">Chopbet</span>
      </h3>

      <div class="text-center text-dark mb-3">
        <h6 class="text-dark text-center">
          <strong>Create your account</strong>
        </h6>
        Enter your details to continue Gaming
      </div>
      <div class="form-wrapper mb-1">
        <form>
          <div class="mb-3">
            <input
              style="
                border: 1px black solid;
                border-radius: 5px;
                background-color: white;
                color: black;
                width: 100%;
                height: 50px;
                text-align: center;
              "
              type="tel"
              class="form-control"
              placeholder="Phone number"
              aria-label="Enter Phone Number"
              name="mobile"
              id="msisdn"
              v-model="msisdn"
            />
          </div>
          <div class="mb-3">
            <div
              class="input-group"
              style="
                border: 1px black solid;
                border-radius: 5px;
                background-color: white;
                color: black;
                width: 100%;
                height: 50px;
                text-align: center;
              "
            >
              <input
                :type="type"
                class="form-control"
                id="inputPass"
                style="
                  border-radius: 5px;
                  background-color: white;
                  color: black;
                  text-align: center;
                "
                name="password"
                placeholder="Enter Password"
                aria-label="Enter Password"
                v-model="password"
              />
              <div
                class="input-group-prepend"
                style="
                  border-radius: 5px;
                  background-color: white;
                  color: black;
                  text-align: center;
                "
              >
                <span
                  style="background-color: var(--white)"
                  class="btn btn-sm mt-1"
                  type="button"
                  ><img
                    @click="showPassword"
                    style="width: 20px"
                    :src="btnText"
                /></span>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <div
              class="input-group"
              style="
                border: 1px black solid;
                border-radius: 5px;
                background-color: white;
                color: black;
                width: 100%;
                height: 50px;
                text-align: center;
              "
            >
              <input
                :type="type"
                class="form-control"
                id="signupPassword1"
                style="
                  border-radius: 5px;
                  background-color: white;
                  color: black;
                  text-align: center;
                "
                name="password_2"
                placeholder="Confirm Password"
                aria-label="Confirm Password"
                v-model="password1"
              />
              <div
                class="input-group-prepend"
                style="
                  border-radius: 5px;
                  background-color: white;
                  color: black;
                  text-align: center;
                "
              >
                <span
                  style="background-color: var(--white)"
                  class="btn btn-sm mt-1"
                  type="button"
                  ><img
                    @click="showPassword"
                    style="width: 20px"
                    :src="btnText"
                /></span>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="disclaimer text-center text-dark">
        By registering for Chopbet, you confirm that you are 18 years old or
        over and agree with the
        <a class="text-dark">Terms, conditions and policies</a> of Chopbet
      </div>

      <div class="button-wrapper text-center mb-3">
        <a
          v-if="!spinner"
          @click="handleSignupClick"
          class="join-button py-2 form-control"
          >Join</a
        >
        <a v-if="spinner" class="join-button py-2 form-control">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
            <br />
          </div>
        </a>
      </div>

      <div class="login-button-wrapper text-center p-3 mb-3">
        <div class="text-center text-dark mb-3">
          Already have an account?
          <a @click="setLogin">
            <span style="text-decoration: underline"> Login</span></a
          >
          <br />
        </div>
      </div>
    </section>

    <section class="register-form-wrapper p-4" v-show="action_verify_password">
      <h3 class="text-dark text-center">
        Registration <span class="text-dark">Code</span>
      </h3>
      <div class="text-center text-dark mb-3">
        Check your phone for an SMS verification code
      </div>
      <div class="form-wrapper mb-3">
        <form>
          <div class="mb-0">
            <input
              style="
                border: 1px black solid;
                border-radius: 5px;
                background-color: white;
                color: black;
                width: 100%;
                height: 50px;
                text-align: center;
              "
              type="tel"
              class="form-control"
              placeholder="Enter code"
              id="code"
              aria-label="Enter Code"
              v-model="code"
            />
          </div>
          <small class="text-blue mb-3"
            >Enter the 4 digit code sent to your phone</small
          >
        </form>
      </div>

      <div class="disclaimer text-center text-dark">
        By activating your Chopbet account, you confirm that you are 18 years
        old or over and agree with the
        <a class="text-dark">Terms, conditions and policies</a> of Chopbet
      </div>

      <div class="button-wrapper text-center mb-3">
        <a @click="handleVerifyClick" class="join-button py-2 form-control"
          >Activate Account</a
        >
      </div>

      <div class="login-button-wrapper text-center p-3 mb-3">
        <div class="text-center text-dark mb-3">
          Didn’t get an SMS code?<br />
          Let’s try again in<br />
          (15 seconds)
        </div>
        <a href="#n" class="login-button py-2 form-control">Resend</a>
      </div>
    </section>

    <section class="register-form-wrapper p-4" v-show="action_reset_password">
      <h3 class="text-dark text-center">
        Reset your <span class="text-dark">Password</span>
      </h3>
      <div class="text-center text-dark mb-3">Enter your phone number</div>
      <div class="form-wrapper mb-3">
        <form>
          <div class="mb-3">
            <input
              style="
                border: 1px black solid;
                border-radius: 5px;
                background-color: white;
                color: black;
                width: 100%;
                height: 50px;
                text-align: center;
              "
              type="tel"
              class="form-control"
              id="msisdn1"
              placeholder="Phone number"
              aria-label="Amount (to the nearest dollar)"
              v-model="msisdn"
            />
          </div>
        </form>
      </div>

      <div class="button-wrapper text-center mb-3">
        <a
          v-if="!spinner"
          @click="handleResetClick"
          class="join-button py-2 form-control"
          >Get Reset Code</a
        >
        <a v-if="spinner" class="join-button py-2 form-control">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
            <br />
          </div>
        </a>
      </div>

      <div class="login-button-wrapper text-center p-3 mb-1">
        <div class="text-center text-dark mb-3">
          Already have an account?
          <a @click="setLogin">
            <span style="text-decoration: underline">Login</span></a
          ><br />
        </div>
      </div>
    </section>

    <section class="register-form-wrapper p-4" v-show="action_change_password">
      <h3 class="text-dark text-center">
        Reset your <span class="text-dark">Password</span>
      </h3>
      <div class="text-center text-dark mb-3">
        Password reset code has been send to your mobile number. Enter the code
        you have received in your phone and your new password.
      </div>
      <div class="form-wrapper mb-3">
        <form>
          <div class="mb-3">
            <input
              style="
                border: 1px black solid;
                border-radius: 5px;
                background-color: white;
                color: black;
                width: 100%;
                height: 50px;
                text-align: center;
              "
              type="tel"
              class="form-control"
              id="phoneInput"
              placeholder="Phone number 07 or 01"
              aria-label="Enter Phone number"
              name="mobile"
              v-model="msisdn"
            />
          </div>
          <div class="mb-3">
            <input
              style="
                border: 1px black solid;
                border-radius: 5px;
                background-color: white;
                color: black;
                width: 100%;
                height: 50px;
                text-align: center;
              "
              type="tel"
              class="form-control"
              placeholder="Enter code"
              id="code1"
              aria-label="Enter Code"
              v-model="code"
            />
          </div>
          <div class="mb-3">
            <div
              class="input-group"
              style="
                border: 1px black solid;
                border-radius: 5px;
                background-color: white;
                color: black;
                width: 100%;
                height: 50px;
                text-align: center;
              "
            >
              <input
                :type="type"
                class="form-control"
                id="signupPassword3"
                style="
                  border-radius: 5px;
                  background-color: white;
                  color: black;
                  text-align: center;
                "
                name="password"
                placeholder="New Password"
                aria-label="New Password"
                v-model="password"
              />
              <div
                class="input-group-prepend"
                style="
                  border-radius: 5px;
                  background-color: white;
                  color: black;
                  text-align: center;
                "
              >
                <span
                  style="background-color: var(--white)"
                  class="btn btn-sm mt-1"
                  type="button"
                  ><img
                    @click="showPassword"
                    style="width: 20px"
                    :src="btnText"
                /></span>
              </div>
            </div>
          </div>
          <div class="mb-0">
            <div
              class="input-group"
              style="
                border: 1px black solid;
                border-radius: 5px;
                background-color: white;
                color: black;
                width: 100%;
                height: 50px;
                text-align: center;
              "
            >
              <input
                :type="type"
                class="form-control"
                id="signupPassword1"
                style="
                  border-radius: 5px;
                  background-color: white;
                  color: black;
                  text-align: center;
                "
                name="password_2"
                placeholder="Confirm New Password"
                aria-label="Confirm New Password"
                v-model="password1"
              />
              <div
                class="input-group-prepend"
                style="
                  border-radius: 5px;
                  background-color: white;
                  color: black;
                  text-align: center;
                "
              >
                <span
                  style="background-color: var(--white)"
                  class="btn btn-sm mt-1"
                  type="button"
                  ><img
                    @click="showPassword"
                    style="width: 20px"
                    :src="btnText"
                /></span>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="disclaimer text-center d-none text-dark">
        By continuing, you confirm that you are 18 years old or over and agree
        with the <a class="text-dark">Terms, conditions and policies</a> of
        Chopbet
      </div>

      <div class="button-wrapper text-center mb-3">
        <a
          v-if="!spinner"
          @click="handleChangePasswordClick"
          class="join-button py-2 form-control"
          >Change Password</a
        >
        <a v-if="spinner" class="join-button py-2 form-control">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
            <br />
          </div>
        </a>
      </div>

      <div class="login-button-wrapper text-center p-3 mb-1">
        <div class="text-center text-dark mb-3">
          Already have an account?
          <a @click="setLogin">
            <span style="text-decoration: underline">Login</span></a
          ><br />
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
label {
  font-weight: 600;
}

.input-signup,
.input-login {
  border: 1px solid #ccc;
  border-radius: 0.5em;
  height: 3em;
}
</style>

<script>
import axios from "@/services/identity";
import { pushToDataLayer } from "@/utils/gtm";
import { mapState } from "vuex";

export default {
  name: "Login",
  components: {},
  comments: {},
  data: function () {
    return {
      msisdn: "",
      password: "",
      password1: "",
      error: [],
      warning: [],
      success: [],
      loading: "",
      spinner: false,
      action_login: true,
      action_signup: false,
      action_verify_password: false,
      action_reset_password: false,
      action_change_password: false,
      code: "",
      placeBet: 0,
      type: "password",
      type2: "password",
      type3: "password",
      btnText: "/img/icons/eye.svg",
      btnText2: "/img/other/eye.svg",
      btnText3: "/img/other/eye.svg",
      showSuccessModal: false,
      providerID: parseInt(process.env.VUE_APP_PROVIDER_ID_SPRIBE),
      gameID: "aviator",
      gameName: "Aviator",
      image: "/img/home/Aviator.jpg",
      crashGameUrl: "aviator",
    };
  },
  computed: {
    phoneInvalid: function () {
      if (this.msisdn.length < 10 && this.msisdn.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    ...mapState(["referralCode"]),
  },
  methods: {
    handleLoginClick(event) {
      this.login(event);
    },
    trackLoginButtonClick(event) {
      pushToDataLayer("gtm.login", {
        category: "Button",
        action: "Click",
        label: "Login",
        element: event.target,
      });
    },

    login: function (event) {
      this.reset();
      this.removeAuth();

      this.msisdn = this.msisdn.replace(/\D/g, "");

      if (this.msisdn.length < 9 || parseInt(this.msisdn) === 0) {
        this.setError("Please enter a valid mobile number");
        return;
      }

      if (this.password.length < 4) {
        vm.setSuccess("Please enter a password of more than 4 characters");
        return;
      }

      this.loading = "loading";
      this.spinner = true;

      var vm = this;

      var path = "/login";

      var currentDate = new Date();
      var login_tag = parseInt(
        currentDate.getTime() +
          "" +
          Math.floor(Math.random() * (9 * Math.pow(10, 2))) +
          Math.pow(10, 2)
      );
      this.setValue("login_tag", login_tag);

      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");

      console.log("here we are");

      axios
        .post(
          path,
          JSON.stringify({
            msisdn: parseInt(this.msisdn),
            password: this.password,
            login_tag: login_tag,
            source: utm_source,
            medium: utm_medium,
            campaign: utm_campaign,
            referrer: document.referrer,
          })
        )
        .then((res) => {
          vm.loading = "";
          vm.spinner = false;

          var profile = res.data;
          var status = res.status;

          console.log("status " + status);

          if (parseInt(status) === 201 || parseInt(status) === 204) {
            vm.setVerifyAccount();
            return;
          }

          vm.setProfile(profile);
          var auth = profile.auth;

          vm.trackLoginButtonClick(event);

          vm.setAuth(auth);
          console.log("profile " + profile.t);
          vm.setSuccess("Login successful!");
          vm.EventBus.$emit("init:mqtt");

          setTimeout(() => {
            this.$router.push({
              name: "aviator",
              params: {
                IsDemo: 0,
                providerID: parseInt(process.env.VUE_APP_PROVIDER_ID_SPRIBE),
                gameName: "Aviator",
                gameID: "aviator",
              },
            });
          }, 2000);
        })
        .catch((err) => {
          vm.loading = "";
          vm.spinner = false;

          if (err.response) {
            this.setError("Login failed!");
          } else if (err.request) {
            this.setError(`${this.$t("checkYourNetwork")}`);
            console.log(JSON.stringify(err.request));
          } else {
            this.setError(`${this.$t("checkYourNetwork")}`);
            console.log(JSON.stringify(err));
          }
        });
    },
    handleBlur: function () {
      if (this.msisdn.length < 9 && this.msisdn.length > 0) {
        this.setError("Please enter a valid mobile number");
        document.getElementById("phoneInput").classList.add("is-invalid");
      } else {
        document.getElementById("phoneInput").classList.remove("is-invalid");
      }
    },
    getBrowserInfo() {
      return navigator.userAgent || "unknown";
    },

    getDeviceInfo() {
      return `${navigator.platform} ${navigator.userAgent}`;
    },

    getEngineInfo() {
      return "JavaScript";
    },

    getVersionInfo() {
      return navigator.appVersion || "unknown";
    },

    handleSignupClick(event) {
      this.signup(event);
    },
    trackSignupButtonClick(event) {
      pushToDataLayer("gtm.register", {
        category: "Button",
        action: "Click",
        label: "Register",
        element: event.target,
      });
    },
    signup: function (event) {
      this.reset();

      this.msisdn = this.msisdn.replace(/\D/g, "");

      if (this.msisdn.length < 9 || parseInt(this.msisdn) === 0) {
        this.setError("Please enter a valid mobile number");
        return;
      }

      if (this.password.length < 4) {
        this.setError("Please enter a password of more than 4 characters");
        return;
      }

      if (this.password !== this.password1) {
        this.setError("Your passwords do not match");
        return;
      }

      this.loading = "loading";
      this.spinner = true;

      var vm = this;
      var path = "/signup";

      var browser = this.getBrowserInfo();
      var deviceInfo = this.getDeviceInfo();
      var engine = this.getEngineInfo();
      var versionInfo = this.getVersionInfo();

      var channelId = parseInt(this.getValue("channel_id")) || 0;

      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");
      var btag = this.getValue("btag");

      console.log("Register method triggered");
      console.log("Document Referrer:", document.referrer);

      console.log("Register method triggered");
      console.log("Document Referrer:", document.referrer);
      console.log("Referral Code:", this.referralCode);

      axios
        .post(
          path,
          JSON.stringify({
            browser: browser,
            channel_id: channelId,
            device_info: deviceInfo,
            engine: engine,
            version_info: versionInfo,
            msisdn: parseInt(this.msisdn),
            password: this.password,
            source: utm_source,
            medium: utm_medium,
            campaign: utm_campaign,
            referrer: document.referrer,
            referral_code: this.referralCode,
            btag: btag,
          })
        )
        .then((res) => {
          vm.spinner = false;
          vm.loading = "";

          console.log("Response:", JSON.stringify(res, undefined, 2));

          var status = res.status;

          if (parseInt(status) === 202) {
            vm.setLogin();
            return;
          }

          if (parseInt(status) === 201 || parseInt(status) === 204) {
            vm.setVerifyAccount();
            return;
          }

          vm.trackSignupButtonClick(event);

          vm.setSuccess("Signup successful!");
          vm.setVerifyAccount();
        })
        .catch((err) => {
          vm.spinner = false;
          vm.loading = "";

          if (err.response) {
            this.setError(err.response.data.error_message);
          } else if (err.request) {
            this.setError(`${this.$t("checkYourNetwork")}`);
            console.log(JSON.stringify(err.request));
          } else {
            this.setError(`${this.$t("checkYourNetwork")}`);
            console.log(JSON.stringify(err));
          }
        });
    },

    handleResetClick(event) {
      this.getResetCode(event);
    },
    trackResetButtonClick(event) {
      pushToDataLayer("gtm.reset", {
        category: "Button",
        action: "Click",
        label: "Reset",
        element: event.target,
      });
    },
    getResetCode: function (event) {
      this.reset();

      if (this.msisdn.length < 9) {
        this.setError("Please enter a valid mobile number");
        return;
      }

      this.spinner = true;
      this.loading = "loading";

      var vm = this;
      var path = process.env.VUE_APP_URL_PASSWORD_RESET;

      var payload = {
        msisdn: parseInt(this.msisdn),
      };

      console.log("Request Payload:", payload);

      axios
        .patch(path, payload, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          vm.spinner = false;
          vm.loading = "";

          console.log(JSON.stringify(res));

          vm.trackResetButtonClick(event);

          this.setError("Password reset code has been sent to your phone");

          vm.setChangePassword();
        })
        .catch((err) => {
          vm.spinner = false;
          console.log(JSON.stringify(err));

          if (err.response) {
            this.setError(err.response.data.error_message);
            console.log(JSON.stringify(err.response.data.message));
          } else if (err.request) {
            this.setError(`${this.$t("checkYourNetwork")}`);
            console.log(JSON.stringify(err.request));
          } else {
            this.setError(`${this.$t("checkYourNetwork")}`);
            console.log(JSON.stringify(err));
          }
        });
    },
    handleChangePasswordClick(event) {
      this.changePassword(event);
    },
    trackChangePasswordButtonClick(event) {
      pushToDataLayer("gtm.changePassword", {
        category: "Button",
        action: "Click",
        label: "Change Password",
        element: event.target,
      });
    },
    changePassword: function (event) {
      this.reset();

      if (this.password.length < 4) {
        this.setError("Please enter a password of more than 4 characters");
        return;
      }

      if (this.password !== this.password1) {
        this.setError("Your passwords do not match");
        return;
      }

      if (this.code.length < 4) {
        this.setError("Please enter a valid verification code");
        return;
      }

      this.spinner = true;
      this.loading = "loading";

      var vm = this;
      var path = process.env.VUE_APP_URL_PASSWORD_CHANGE;

      var payload = {
        msisdn: parseInt(this.msisdn),
        password: this.password,
        code: parseInt(this.code),
      };

      console.log("Request Payload:", payload);

      axios
        .patch(path, payload, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(JSON.stringify(res));

          vm.spinner = false;
          vm.loading = "";

          vm.trackChangePasswordButtonClick(event);

          vm.setSuccess("Your password has been changed successfully");

          vm.setLogin();
        })
        .catch((err) => {
          vm.spinner = false;
          vm.loading = "";

          if (err.response) {
            this.setError(err.response.data.message);
            console.log(JSON.stringify(err.response.data.message));
          } else if (err.request) {
            this.setError(`${this.$t("checkYourNetwork")}`);
            console.log(JSON.stringify(err.request));
          } else {
            this.setError(`${this.$t("checkYourNetwork")}`);
            console.log(JSON.stringify(err));
          }
        });
    },
    handleVerifyClick(event) {
      this.verifyAccount(event);
    },
    trackVerifyButtonClick(event) {
      pushToDataLayer("gtm.verify", {
        category: "Button",
        action: "Click",
        label: "Verify",
        element: event.target,
      });
    },
    verifyAccount: function (event) {
      this.reset();

      if (this.code.length < 4) {
        this.setError("Please enter a valid verification code");
        return;
      }

      this.spinner = true;
      this.loading = "loading";

      var vm = this;
      var path = process.env.VUE_APP_URL_VERIFY;
      var currentDate = new Date();
      var login_tag = parseInt(
        currentDate.getTime() +
          "" +
          Math.floor(Math.random() * (9 * Math.pow(10, 2))) +
          Math.pow(10, 2)
      );
      this.setValue("login_tag", login_tag);

      var utm_source = this.getValue("utm_source") || "";
      var utm_medium = this.getValue("utm_medium") || "";
      var utm_campaign = this.getValue("utm_campaign") || "";

      var payload = {
        msisdn: parseInt(this.msisdn),
        code: parseInt(this.code),
        login_tag: login_tag,
        source: utm_source,
        medium: utm_medium,
        campaign: utm_campaign,
        referrer: document.referrer,
      };

      console.log("Request Payload:", payload);

      axios
        .post(path, payload, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          vm.spinner = false;
          vm.loading = "";
          var profile = res.data;
          var status = res.status;

          console.log("Response Status:", status);
          console.log("Response Data:", profile);

          vm.trackVerifyButtonClick(event);

          vm.setProfile(profile);
          var auth = profile.auth;
          vm.setAuth(auth);

          vm.setSuccess(
            "Success",
            "Congratulations! Your account has been verified successfully"
          );

          vm.EventBus.$emit("init:mqtt");

          if (parseInt(vm.placeBet) === 1) {
            vm.setValue("placeBet", 0);
            vm.EventBus.$emit("event:betslip:show");
          } else {
            setTimeout(() => {
              window.location.href = "/";
            }, 2000);
          }
        })
        .catch((err) => {
          vm.spinner = false;
          vm.loading = "";
          console.log("Error:", err);

          if (err.response) {
            console.log("Error Response Data:", err.response.data);
            console.log("Error Response Status:", err.response.status);
            console.log("Error Response Headers:", err.response.headers);

            this.setError(err.response.data.error_message);
          } else if (err.request) {
            this.setError(`${this.$t("checkYourNetwork")}`);
            console.log("Error Request:", err.request);
          } else {
            this.setError(`${this.$t("checkYourNetwork")}`);
            console.log("Error Message:", err.message);
          }
        });
    },
    setSignup: function () {
      this.action_login = false;
      this.action_signup = true;
      this.action_change_password = false;
      this.action_reset_password = false;
      this.action_verify_password = false;
    },
    setLogin: function () {
      this.action_login = true;
      this.action_signup = false;
      this.action_change_password = false;
      this.action_reset_password = false;
      this.action_verify_password = false;
    },
    setGetResetCode: function () {
      this.action_login = false;
      this.action_signup = false;
      this.action_change_password = false;
      this.action_reset_password = true;
      this.action_verify_password = false;
    },
    setChangePassword: function () {
      this.action_login = false;
      this.action_signup = false;
      this.action_change_password = true;
      this.action_reset_password = false;
      this.action_verify_password = false;
    },
    setVerifyAccount: function () {
      this.action_login = false;
      this.action_signup = false;
      this.action_change_password = false;
      this.action_reset_password = false;
      this.action_verify_password = true;
    },
    showPassword: function () {
      if (document.getElementById("inputPass").type == "password") {
        document.getElementById("inputPass").type = "text";
        document.getElementById("inputPass").style.color = "white";
      } else {
        document.getElementById("inputPass").type = "password";
        document.getElementById("inputPass").style.color =
          "rgba(255,255,255,.75)";
      }
      // if(document.getElementById('signupPassword1').type == "password"){
      //   document.getElementById('signupPassword1').type = "text"
      // }else{
      //   document.getElementById('signupPassword1').type = "password"
      // }
      if (this.type === "password") {
        this.type = "text";
        this.btnText = "/img/icons/eye-slash.svg";
      } else {
        this.type = "password";
        this.btnText = "/img/icons/eye.svg";
      }
    },

    showPasswordReset: function () {
      if (document.getElementById("signupPassword3").type == "password") {
        document.getElementById("signupPassword3").type = "text";
        document.getElementById("signupPassword3").style.color = "white";
      } else {
        document.getElementById("signupPassword3").type = "password";
        document.getElementById("signupPassword3").style.color =
          "rgba(255,255,255,.75)";
      }
      if (this.type2 === "password") {
        this.type2 = "text";
        this.btnText2 = "/img/other/eyeslash.svg";
      } else {
        this.type2 = "password";
        this.btnText2 = "/img/other/eye.svg";
      }
    },
    showPasswordReset2: function () {
      if (document.getElementById("signupPassword4").type == "password") {
        document.getElementById("signupPassword4").type = "text";
        document.getElementById("signupPassword4").style.color = "white";
      } else {
        document.getElementById("signupPassword4").type = "password";
        document.getElementById("signupPassword4").style.color =
          "rgba(255,255,255,.75)";
      }
      if (this.type3 === "password") {
        this.type3 = "text";
        this.btnText3 = "/img/other/eyeslash.svg";
      } else {
        this.type3 = "password";
        this.btnText3 = "/img/other/eye.svg";
      }
    },
  },
  mounted: function () {
    this.$store.dispatch("setCurrentPage", "login");
    this.placeBet = this.getValue("placeBet");
    var p = this.getProfile();
    if (!p) {
      console.log("");
    } else {
      this.$router.push({ name: "home", params: {} });
      return;
    }
  },
};
</script>
